import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DestroyRef, Inject, Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Partner } from '@app/models/partner/IPartner';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { CookiesService } from './cookies.service';
import { Location } from '@angular/common';
import { PartnerService } from './partner.service';
import { environment } from 'src/environments/environment';
import { EncryptionService } from './encyption.service';
import { makeStateKey, TransferState } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// import { DomSanitizer } from '@angular/platform-browser';

const STATE_KEY_PARTNER = makeStateKey('partnerdata');
@Injectable({
    providedIn: 'root',
})
export class AuthGuardService {
    protected currentPartnerId: string = null;
    protected currentPartner!: Partner;
    protected contentPartnerSubject: BehaviorSubject<string>;
    protected contentPartnerDataSubject: BehaviorSubject<Partner>;
    protected hidenHeaderFooterSubject: BehaviorSubject<boolean>;

    protected trackingid: string;
    protected sessionid: string;

    isBrowser: boolean = false;
    destroyRef = inject(DestroyRef);
    constructor(
        protected partnerService: PartnerService,
        protected encService: EncryptionService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected auth: AuthService,
        protected cookies: CookiesService,
        protected state: TransferState,
        protected ngZone: NgZone,
        protected location: Location,
        // protected sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
    ) {
        this.contentPartnerSubject = new BehaviorSubject(null);
        this.hidenHeaderFooterSubject = new BehaviorSubject(false);
        this.contentPartnerDataSubject = new BehaviorSubject({} as Partner);

        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    async checkPartner(id) {
        if (id === 'reisen') {
            id = 'main';
        }
        if (this.currentPartner?.parentid !== id) {
            const partres = this.state.get(STATE_KEY_PARTNER, <any>{});
            if (partres?.data?.length > 0) {
                // console.log(partres)
                const tokenFromUI = partres.data?.slice(-16);
                const data = partres.data?.slice(0, (partres.data.length - 16));
                this.currentPartner = JSON.parse(this.encService.deencrypt(tokenFromUI, data));
                // console.log(this.currentPartner)
            }
            this.state.set(STATE_KEY_PARTNER, <any>{}); // remove content for next loading
            // const start = Date.now();
            if (this.currentPartner?.partnerid?.length > 0) {
                this.initPartnerData();
            } else {
                this.partnerService.request({ partnerid: String(id).toLowerCase() }, 'checkpartner').pipe(
                    takeUntilDestroyed(this.destroyRef)
                ).subscribe((partres) => {
                    if (partres?.data?.length > 0) {
                        const tokenFromUI = partres.data?.slice(-16);
                        const data = partres.data?.slice(0, (partres.data.length - 16));
                        const res = JSON.parse(this.encService.deencrypt(tokenFromUI, data));
                        if (isPlatformServer(this.platformId)) {
                            this.state.set(STATE_KEY_PARTNER, <any>partres);
                        }
                        if (res?.partnerid?.length > 0) {
                            this.currentPartner = res as Partner;
                            this.initPartnerData();
                        } else {
                            this.ngZone.run(() => {
                                this.router.navigate(['reisen']).then(() => {
                                    if (this.isBrowser) {
                                        window.location.reload();
                                    }
                                });
                            });
                        }
                        // console.log((Date.now() - start) / 1000);
                    } else {
                        this.ngZone.run(() => {
                            this.router.navigate(['reisen']).then(() => {
                                if (this.isBrowser) {
                                    window.location.reload();
                                }
                            });
                        });
                    }
                });
            }
        }
    }

    initPartnerData(): void {
        this.setCustomColors(this.currentPartner);
        if (this.currentPartner?.ico?.length > 0) {
            // eslint-disable-next-line no-undef
            const favIcon: HTMLLinkElement = this.document.querySelector('#partnerFavIcon');
            if (favIcon) {
                favIcon.href = 'https://suntour-reisen.de/logo/' + this.currentPartner.partnerid + '/' + this.currentPartner.ico;
            } else {
                favIcon.href = '/favicon.ico';
            }
        }
        this.currentPartnerId = String(this.currentPartner.partnerid).toLowerCase();
        for (let i = 0; i < this.currentPartner.agenturen?.length; i++) {
            if (!this.currentPartner.agenturen[i].aktiv) {
                this.currentPartner.agenturen.splice(i, 1);
                i--;
            }
        }
        this.contentPartnerDataSubject.next(this.currentPartner);
        this.contentPartnerSubject.next(this.currentPartnerId);
        if (this.isBrowser) {
            this.auth.isAuthenticated(this.currentPartnerId);
        }
        if (environment.production && this.isBrowser) {
            this.checktid();
        }
    }

    getPartnerCurrency () {
        return this.currentPartner.partnercurrency;
    }

    getlogo(): string {
        return this.currentPartner?.logo;
    }

    protected setCustomColors(part: Partner) {
        this.document.body.classList.add(part?.partnerid);
        if (part?.searchpanelbgcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-bg-color', part.searchpanelbgcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-bg-color', '#8e0622');
        }

        if (part?.searchpanelcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-text-color', part.searchpanelcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-text-color', '#fff');
        }

        if (part?.successcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-success-color', part.successcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-success-color', 'rgb(0,130,0)');
        }

        if (part?.successtextcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-success-text-color', part.successtextcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-success-text-color', '#fff');
        }

        if (part?.pricediscounttextcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-price-discount-text-color', part.pricediscounttextcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-price-discount-text-color', '#fff');
        }

        if (part?.pricediscountbgcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-price-discount-bg-color', part.pricediscountbgcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-price-discount-bg-color', '#ff5c5c');
        }

        if (part?.errorcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-error-color', part.errorcolor);
        }

        if (part?.errortextcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-error-text-color', part.errortextcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-error-text-color', 'rgb(180,30,30)');
        }

        if (part?.searchpanelcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-text-color', part.searchpanelcolor);
        }

        if (part?.bgcolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-bgcolor', part?.bgcolor);
        } else {
            this.document.documentElement.style.setProperty('--cl-bgcolor', '#f3f3f3');
        }

        if (part?.textprimary?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-textprimary', part.textprimary);
        } else {
            this.document.documentElement.style.setProperty('--cl-textprimary', '#545454');
        }

        if (part?.textsecondary?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-textsecondary', part.textsecondary);
        } else {
            this.document.documentElement.style.setProperty('--cl-textsecondary', '#545454');
        }

        if (part?.texta?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-texta', part.texta);
        } else {
            this.document.documentElement.style.setProperty('--cl-texta', '#545454');
        }

        if (part?.textahover?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-textahover', part.textahover);
        }

        if (part?.fontfamily?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-fontfamily', part.fontfamily);
        } else {
            this.document.documentElement.style.setProperty('--cl-fontfamily', '"Helvetica Neue", Helvetica, Arial, sans-serif');
        }

        if (part?.fontsize?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-fontsize', part.fontsize);
        } else {
            this.document.documentElement.style.setProperty('--cl-fontsize', '16px');
        }

        if (part?.margin?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-margin', part.margin);
        } else {
            this.document.documentElement.style.setProperty('--cl-margin', '0');
        }

        if (part?.fontweight?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-fontweight', part.fontweight);
        } else {
            this.document.documentElement.style.setProperty('--cl-fontweight', '300');
        }

        if (part?.letterspacing?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-letterspacing', part.letterspacing);
        } else {
            this.document.documentElement.style.setProperty('--cl-letterspacing', '0.4px');
        }

        if (part?.lineheight?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-lineheight', part.lineheight);
        } else {
            this.document.documentElement.style.setProperty('--cl-lineheight', '1.4');
        }

        if (part?.placeholdercolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-placeholdercolor', part.placeholdercolor);
        }

        if (part?.linescolor?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-linescolor', part.linescolor);
        }

        if (part?.lineheight?.length > 0) {
            this.document.documentElement.style.setProperty('--cl-lineheight', part.lineheight);
        } else {
            this.document.documentElement.style.setProperty('--cl-lineheight', '1.4');
        }
        // console.log(part?.extrastyle)
        if (part?.extrastyle?.length > 0) {
            const cleverstyle = this.document.getElementById('clever-custom-style');
            if (cleverstyle) {
                cleverstyle.innerText = part.extrastyle.replaceAll('\n', '');
            }
        }
    }

    getPrtBg(): string {
        return this.currentPartner?.searchpanelbgcolor;
    }

    getPartnerId(): string {
        return String(this.currentPartnerId).toLowerCase();
    }

    getPartnerLicense(): string {
        return this.currentPartner?.traffics?.licence;
    }

    getGiataLicense(): string {
        return this.currentPartner?.giatalicence;
    }

    getPartner(): Partner {
        return this.currentPartner;
    }

    sethidenHeaderFooterSubject(val): void {
        this.hidenHeaderFooterSubject.next(val);
    }

    get getPartnerid$() {
        return this.contentPartnerSubject.asObservable();
    }

    get gethidenHeaderFooter$() {
        return this.hidenHeaderFooterSubject.asObservable();
    }

    get getPartner$() {
        return this.contentPartnerDataSubject.asObservable();
    }

    getrecapchekey(): Promise<string> {
        return new Promise(result => {
            this.getPartner$.pipe(
                takeUntilDestroyed(this.destroyRef)
            ).subscribe(partner => {
                if (partner.partnerid?.length > 0) {
                    result(partner.recaptchaSiteKeyV3 || '6LfRh1IeAAAAAHtWRke9B5YG-QTnu3HpBhzAckg0');
                } else {
                    result('6LfRh1IeAAAAAHtWRke9B5YG-QTnu3HpBhzAckg0')
                }
            });
        });
    }

    async checktid() {
        this.trackingid = this.cookies.getCookie('CR_' + this.currentPartnerId + '_TID');
        this.partnerService.request({ trackingid: this.trackingid, partnerid: this.currentPartnerId }, 'checktid').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((res) => {
            if (res.trackingid?.length === 24) {
                this.trackingid = res.trackingid;
                this.sessionid = res.sessionid;
                this.cookies.setCookie('CR_' + this.currentPartnerId + '_TID', this.trackingid, 365, this.currentPartnerId);
                window.sessionStorage.setItem('CR_' + this.currentPartnerId + '_SID', this.sessionid);

                this.setSessionTrackingurls(this.location.path());
                this.location.onUrlChange(url => this.setSessionTrackingurls(url));
            }
        });
    }

    async setSessionTrackingurls(url) {
        const target = {
            partnerid: this.currentPartnerId,
            trackingid: this.trackingid,
            sessionid: this.sessionid,
            url: url
        };

        this.partnerService.request(target, 'setSessionTrackingurls').pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => { });
    }
}
